import React, { useState } from 'react';
import Button from '../Button';
import './index.scss';
import { IntersectionObserverComponent } from '../../../hooks/useIntersectionObserver';

export type ActionButtonsProps = {
  /** Makes the component sticky to the bottom of the upper level block element (Page) */
  isSticky?: boolean;
  isDisabled?: boolean;
  proceedButton?: { label: string; onClick: () => void };
  /** Shows a loading spinner on the proceed button */
  isLoading?: boolean;
  /** Shows a static error message below the proceed button for detail pages */
  hasError?: boolean;
  cancelButton: { label: string; onCancel: () => void };
};

const ActionButtons = ({
  isSticky: isStickyProp,
  isDisabled,
  proceedButton,
  isLoading,
  cancelButton,
  hasError,
}: ActionButtonsProps) => {
  const [isSticky, setIsSticky] = useState(false);

  return (
    <>
      <div className={`action-buttons ${isSticky ? 'sticky white-bg' : ''}`}>
        {proceedButton ? (
          <Button
            isDisabled={isDisabled}
            onClick={proceedButton.onClick}
            label={proceedButton.label}
            loading={isLoading}
          />
        ) : null}
        {hasError && <div className='form-error'>Bitte geben Sie die fehlenden Daten ein</div>}
        <div onClick={cancelButton.onCancel} className='cancel-button'>
          {cancelButton.label}
        </div>
      </div>
      {isStickyProp && (
        <IntersectionObserverComponent
          onIntersection={(isIntersecting) => setIsSticky(!isIntersecting)}
        />
      )}
    </>
  );
};

export default ActionButtons;
