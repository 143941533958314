/* eslint-disable no-useless-escape */

const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EMAIL_OR_NULL_REGEXP =
  /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GERMAN_IBAN_REGEXP = /^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/i;

const GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP =
  /^(?!DE)[A-Z]{2}[0-9A-Z]*$|^DE\d{2}\s?([0-9a-zA-Z]{4}\s?){4}[0-9a-zA-Z]{2}$/i;

const IBAN_FORMAT_REGEXP = /^[A-Z]{2}[0-9]{2,}$/i;

const EMPTY_REGEXP = /^(?!\s*$).+/;

const MORE_THAN_2_CHAR_REGEXP = /[a-z0-9]{2,}/i;

const MORE_THAN_2_CHAR_OR_NULL_REGEXP = /^$|[a-z0-9]{2,}/i;

const PHONE_REGEXP = /^(((\+|00)?49)|0)[1-9]{1}\d{5,12}$/;

const PHONE_OR_NULL_REGEXP = /^$|^(((\+|00)?49)|0)[1-9]{1}\d{5,12}$/;

const POST_CODE_REGEXP = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/;

const HIGHLIGHT_REGEXP = /[/\\^$*+?#%~@!.()|[\]{}]/g; // Escape special characters (except hyphen and &)

const SPACE_REGEXP = /[ ]/g;

const DECIMAL_REGEXP = /[^0-9,]/;

const DESCRIPTION_REGEXP = /[^a-zA-Z\d/?:().,'\-+& ]/g;

const EMOJI_REGEXP =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;

export {
  EMAIL_REGEXP,
  EMAIL_OR_NULL_REGEXP,
  GERMAN_IBAN_REGEXP,
  GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP,
  IBAN_FORMAT_REGEXP,
  EMPTY_REGEXP,
  MORE_THAN_2_CHAR_REGEXP,
  MORE_THAN_2_CHAR_OR_NULL_REGEXP,
  PHONE_REGEXP,
  PHONE_OR_NULL_REGEXP,
  POST_CODE_REGEXP,
  HIGHLIGHT_REGEXP,
  SPACE_REGEXP,
  DECIMAL_REGEXP,
  DESCRIPTION_REGEXP,
  EMOJI_REGEXP,
};
