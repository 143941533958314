import React from 'react';
import { HIGHLIGHT_REGEXP } from './regex';

const highlightSearchText = (text: string, searchText: string): JSX.Element => {
  const searchParts = searchText?.split(/[-\s]+/) || []; // hyphens or spaces
  const sanitizedParts = searchParts
    .filter((part) => part.trim() !== '')
    .map((part) => part.replace(HIGHLIGHT_REGEXP, '\\$&'));

  const regex = sanitizedParts.length // Only create the regex if there are chars except the special ones
    ? new RegExp(`(${sanitizedParts.join('|')})`, 'gi')
    : null;

  return (
    <span data-testid='search-field-item'>
      {searchText && regex
        ? text.split(regex).map((part, index) =>
            searchParts.some((searchPart) => searchPart.toLowerCase() === part.toLowerCase()) ? (
              // eslint-disable-next-line react/no-array-index-key
              <b key={part + index} data-testid='search-field-bold'>
                {part}
              </b>
            ) : (
              part
            ),
          )
        : text}
    </span>
  );
};

export default highlightSearchText;
