import moment, { Moment } from 'moment';
import { ItemState } from '../types/Overview/Common';
import { DeviceOutputEnum } from './deviceOutput';
import { NotificationModel, NotificationType } from '../types/Overview/NotificationModel';
import { StandingOrderModel } from '../types/Overview/StandingOrderModel';

const DATE_FORMATS = ['DD.MM.YYYY', 'DD.MM.YY', 'DDMMYYYY', 'DDMMYY', 'YYYY-MM-DD'];

export const parseUserInputDate = (date: string): Moment => {
  let ret = moment(date, DATE_FORMATS[0], true);
  if (!ret.isValid()) {
    for (let i = 1; i < DATE_FORMATS.length; i++) {
      ret = moment(date, DATE_FORMATS[i], true);
      if (ret.isValid()) {
        break;
      }
    }
  }
  return ret;
};

export const formatDate = (date: string) => {
  if (!date) {
    return '';
  }
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY').replace(/\//g, '.');
};

export const formatAmount = (amount: string) => {
  if (!amount) {
    return '';
  }
  return parseFloat(amount).toLocaleString('de-DE', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const formatAmountReverse = (amount: string) => {
  if (amount && amount.trim()) {
    let result = amount.replace('.', '');
    result = result.replace(',', '.');
    return parseFloat(result);
  }

  return 0;
};

export const removeNegativeSign = (amount: string) => {
  if (!amount) {
    return '';
  }
  return amount.replace('-', '');
};

const frequencies: Record<string, number> = {
  täglich: 9,
  wöchentlich: 8,
  zweiwöchentlich: 7,
  monatlich: 6,
  zweimonatlich: 5,
  vierteljährlich: 4,
  halbjährlich: 3,
  jährlich: 2,
  unregelmäßig: 1,
  unbekannt: 0,
};

export const sort = (array: any, sortBy: string): any => {
  let tmp: any = [].concat(array);
  switch (sortBy) {
    case 'amount': {
      const positiveAmount = tmp.filter((x: any) => x.amount && x.amount >= 0);
      const negativeAmount = tmp.filter((x: any) => x.amount && x.amount < 0);
      const hasNoTransaction = tmp.filter((x: any) => !x.amount);

      positiveAmount.sort((a: any, b: any) => b.amount - a.amount);
      negativeAmount.sort((a: any, b: any) => a.amount - b.amount);
      hasNoTransaction.sort((a: any, b: any) => b.amount - a.amount);
      tmp = [...positiveAmount, ...negativeAmount, ...hasNoTransaction];
      break;
    }
    case 'frequency': {
      tmp = array.sort(
        (a: any, b: any) => frequencies[b.frequencyLocale] - frequencies[a.frequencyLocale],
      );
      break;
    }
    case 'category': {
      tmp = array.sort((a: any, b: any) => a.categoryLocale.localeCompare(b.categoryLocale), 'de');
      break;
    }
    case 'alphabetical': {
      tmp = array.sort((a: any, b: any) =>
        a.normalizedCounterName
          .toLowerCase()
          .localeCompare(b.normalizedCounterName.toLowerCase(), 'de'),
      );
      break;
    }
    case 'date': {
      tmp = array.sort((a: any, b: any) => {
        const dateA = new Date(a.dates.switchingDate);
        const dateB = new Date(b.dates.switchingDate);
        return dateA.valueOf() - dateB.valueOf();
      });
      break;
    }
    default:
      break;
  }
  return tmp;
};

export const isDesktopOrTablet = () =>
  window.wf_deviceoutput === DeviceOutputEnum.DESKTOP ||
  window.wf_deviceoutput === DeviceOutputEnum.TABLETAPP;

export const scrollToPosition = (position: string) => {
  // timeout for iOS native scroll event
  setTimeout(() => {
    // eslint-disable-next-line radix
    window.scrollTo({ top: parseInt(position) });
  }, 10);
};

export const scrollToTopOfTheElementForMobile = (
  elementWindowTopPosition: number,
  elementTopPosition: number,
  offset = 100,
) => {
  if (isDesktopOrTablet()) {
    return;
  }
  if (elementTopPosition <= 200) return;

  const targetPosition = elementWindowTopPosition + elementTopPosition - offset;
  // timeout for iOS native scroll event
  setTimeout(() => {
    window.scrollTo({ top: targetPosition });
  }, 10);
};

export const setLastScrollPosition = (position?: number) => {
  sessionStorage.setItem('scrollPosition', position?.toString() ?? window.scrollY.toString());
};

export const getLastScrollPosition = () => sessionStorage.getItem('scrollPosition') || '0';

export const removeModifiedTransaction = () => sessionStorage.removeItem('modifiedTransactionId');

export const setModifiedTransaction = (id: string) =>
  sessionStorage.setItem('modifiedTransactionId', id);

export const checkIsTransactionModified = (id: string) =>
  sessionStorage.getItem('modifiedTransactionId') === id;

export const isApprovedState = (itemState: ItemState | null) =>
  itemState === ItemState.APPROVED ||
  itemState === ItemState.EXECUTED ||
  itemState === ItemState.SCHEDULED ||
  itemState === ItemState.SENT;

export const createPrefilledMailLink = (processNumber?: number, newBankName?: string): string => {
  if (processNumber) {
    const body = encodeURIComponent(
      'Sehr geehrte Damen und Herren,\nich habe folgende Frage zum Kontoumzug:\n',
    );

    const subject = `Rückfrage zum Kontoumzug, Vorgangsnummer: ${processNumber} ${
      newBankName ? `bei der ${newBankName}` : ''
    }`;

    return `mailto:kontoumzug@check24.de?subject=${encodeURIComponent(subject)}&body=${body}`;
  }

  return 'mailto:kontoumzug@check24.de';
};

export const scrollToElement = (element?: HTMLElement, offset?: number) => {
  if (!element) return;

  // default offset for desktop and tablet is 40, for mobile and app is 16 for current container paddings and margins
  const defaultOffsetForDevices = isDesktopOrTablet() ? 40 : 16;

  const elementTop = element.getBoundingClientRect().top;

  const targetPosition = elementTop + window.scrollY - (offset || defaultOffsetForDevices);

  setTimeout(() => {
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }, 10);
};

export const filterRelevantNotifications = (notifications: NotificationModel[] | undefined) =>
  notifications?.filter(
    (notification: NotificationModel) =>
      notification.type === NotificationType.RELEVANT &&
      !notification.added &&
      !isApprovedState(notification.state),
  ) || [];

export const checkHasNotifyState = (trans: NotificationModel[] | StandingOrderModel[]): boolean =>
  trans?.some((t: NotificationModel | StandingOrderModel) => t.state === ItemState.NOTIFY);
