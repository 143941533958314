import React from 'react';
import Cookies from 'js-cookie';

export const generatePdfLink = (link: string, appPdfDownload: string) => {
  const c24BankCookie = Cookies.get('c24bank-session');

  // appPdfDownload for giro to understand this link will be downloaded
  return `${link}?appPdfDownload=${appPdfDownload}${
    c24BankCookie ? `&token=${c24BankCookie}` : ''
  }`;
};

export const externalLinkGenerator = (link: string) => {
  try {
    return new URL(link.includes('http') ? link : `https://${link}`).toString();
  } catch {
    throw new Error('Invalid URL');
  }
};

export const externalHyperlinkGenerator = (link: string) => (
  <a href={externalLinkGenerator(link)} target='_blank' rel='noreferrer'>
    {link}
  </a>
);
