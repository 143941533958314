import React, { useEffect, useState } from 'react';
import './index.scss';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../services/ServiceGateway';
import ContactCard from '../../../Common/ContactCard';
import DateInput from '../../../Common/DateInput';
import { ValidationResult } from '../../../../utils/validators';
import { useOverview } from '../../../../contexts/OverviewContext/OverviewContext';
import Tooltip from '../../../Common/Tooltip';
import ActionButtons from '../../../Common/ActionButtons';
import InfoBox from '../../../Common/InfoBox';
import { TooltipStateEnum } from '../../../../contexts/TooltipStateContext/Tooltip.types';
import WelcomeSection from './sections/WelcomeSection';
import { isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import ResetButton, { RESET_BUTTON_INTERSECTION } from '../../../Common/ResetButton';
import { IntersectionObserverComponent } from '../../../../hooks/useIntersectionObserver';

const CloseAccountDetails = () => {
  const { kwsState, setAccountToProcess, setSwitchingDateToProcess } = useKwsState();

  const { overviewData } = useOverview();
  const { closingData } = overviewData!;
  const [accountClosingDate, setAccountClosingDate] = useState(closingData.accountClosingDate);

  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState<Record<string, ValidationResult>>({});
  const [hasShadow, setHasShadow] = useState(false);

  const errorCheck = !Object.keys(validationError).every(
    (key) => validationError[key].valid ?? false,
  );

  const submit = async () => {
    setIsLoading(true);
    try {
      const account = {
        accountClosingDate,
        state: closingData.state,
        isNewBalanceDestinationAccount: false,
      };
      const result = await ServiceGateway.closingData(kwsState!.id, account);
      const isSuccess = result.status >= 200 && result.status < 300;
      if (isSuccess) {
        setAccountToProcess(false);
        setSwitchingDateToProcess(false);
      }
    } catch (error: any) {
      if (error.response.status === 400) {
        if (error.response?.data?.errors) {
          setValidationError({
            ...validationError,
            ...error.response.data.errors.reduce(
              (acc: any, err: any) => ({
                ...acc,
                [err.key]: { valid: false, message: err.message },
              }),
              {},
            ),
          });
        }
      }
    }
    setIsLoading(false);
  };

  const goBack = () => {
    setAccountToProcess(false);
    setSwitchingDateToProcess(false);
  };

  useEffect(() => {
    scrollToPosition('0');
  }, []);

  return (
    <div className='close-account' data-testid='close-account'>
      <ResetButton
        title='zurück zur Übersicht'
        onClick={goBack}
        isSticky={!isDesktopOrTablet()}
        hasShadow={hasShadow}
      />

      <div className='intro-close-account'>
        <WelcomeSection oldBank={closingData.oldBank} />
      </div>
      {!isDesktopOrTablet() && (
        <IntersectionObserverComponent
          onIntersection={(isIntersecting) => setHasShadow(!isIntersecting)}
          placeOfIntersection={RESET_BUTTON_INTERSECTION}
        />
      )}

      {/* Form */}
      <div className='form-container'>
        <div className='description'>
          Sie können individuell wählen, wann Ihr bisheriges Konto geschlossen und der Restsaldo mit
          Ihrem neuen Konto verrechnet werden soll.
        </div>

        {/* New Account */}
        <div className='form-group'>
          <div className='form-header'>
            <div className='title'>Datum der Kontoschließung</div>
            <Tooltip tooltipSectionName={TooltipStateEnum.ACCOUNT_CLOSING_FORM} hasBackground>
              <p>
                Negative Salden können nur dann verrechnet werden, wenn auf dem neuen Konto ein
                Dispokredit in entsprechender Höhe eingerichtet wurde. Der Kontoumzug kann einen
                bestehenden Dispokredit nicht automatisch auf das neue Konto übertragen. Ohne
                Dispokredit muss ein negativer Saldo vor der Kontoschließung ausgeglichen werden.
              </p>
            </Tooltip>
          </div>
          <div className='form-input'>
            <DateInput
              value={accountClosingDate}
              onChange={(v) => setAccountClosingDate(v)}
              onError={(e) =>
                setValidationError((validations) => ({
                  ...validations,
                  'process.closingData': e,
                }))
              }
              validation={validationError?.['process.closingData']}
            />
          </div>
          <InfoBox type='info'>
            <p>
              Das frühestmögliche Datum zur Schließung Ihres bisherigen Kontos wurde automatisch
              errechnet. Erfahrungsgemäß benötigen die Zahlungspartner eine gewisse Zeit, um Ihren
              Kontoumzug zu bestätigen. Aus diesem Grund ist eine frühere Schließung des bisherigen
              Kontos nicht auswählbar.
            </p>
          </InfoBox>
        </div>
      </div>

      <ActionButtons
        isDisabled={errorCheck}
        isLoading={isLoading}
        proceedButton={{ label: 'speichern', onClick: submit }}
        cancelButton={{ label: 'Änderungen verwerfen', onCancel: goBack }}
      />

      <ContactCard />
    </div>
  );
};

export default CloseAccountDetails;
