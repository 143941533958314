import React, { useState } from 'react';
import AlertCardV2 from '../../../../../../Common/AlertCardV2';
import { ClosingDataModel } from '../../../../../../../types/Overview/ClosingDataModel';

type ManualKwsAlertSectionProps = {
  showNotificationAlert: boolean;
  closingData: ClosingDataModel;
};

const ManualKwsAlertSection = ({
  showNotificationAlert,
  closingData,
}: ManualKwsAlertSectionProps) => {
  const [isNotifAlertDetailsShown, setIsNotifAlertDetailsShown] = useState(false);
  const [isStandingOrderAlertDetailsShown, setIsStandingOrderAlertDetailsShown] = useState(false);

  const toggleNotifDetails = () => setIsNotifAlertDetailsShown(!isNotifAlertDetailsShown);
  const toggleStandingOrderDetails = () =>
    setIsStandingOrderAlertDetailsShown(!isStandingOrderAlertDetailsShown);

  return (
    <>
      {showNotificationAlert && (
        <AlertCardV2
          title='Umzug von Zahlungspartnern'
          onChange={toggleNotifDetails}
          show={isNotifAlertDetailsShown}
        >
          <div className='child'>
            <div>
              Sie haben bisher keine Zahlungspartner zu Ihrem Kontoumzug hinzugefügt. Bitte
              informieren Sie Ihre Zahlungspartner bei Bedarf eigenständig über Ihre neue
              {` ${closingData?.newBank?.name}`} IBAN, sodass Ihre Gut- und Lastschriften umgezogen
              werden. Alternativ können Sie unter “Umzugsschritte hinzufügen” Zahlungspartner
              hinzufügen, die wir anschließend automatisch über Ihre neue Bankverbindung
              informieren.
            </div>
          </div>
        </AlertCardV2>
      )}

      <AlertCardV2
        show={isStandingOrderAlertDetailsShown}
        title='Einzurichtende Daueraufträge'
        onChange={toggleStandingOrderDetails}
      >
        <div className='child'>
          <div>
            Leider können Daueraufträge beim manuellen Kontoumzug nicht automatisch umgezogen
            werden. Bitte richten Sie Ihre Daueraufträge bei Bedarf eigenständig auf Ihrem neuen
            {` ${closingData?.newBank?.name}`} Konto ein und vergessen Sie nicht, Ihre Daueraufträge
            bei der bisherigen Bank zu löschen.
          </div>
        </div>
      </AlertCardV2>
    </>
  );
};

export default ManualKwsAlertSection;
