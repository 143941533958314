import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import Spinner from '../../../Spinner';

interface DesktopAutoCompleteProps<T> {
  disabled?: boolean;
  disabledJustInput?: boolean;
  placeholder: string;
  icon?: any;
  className?: string;
  value: string;
  onChange: (v: string) => void;
  onBlurChange?: (value: string) => void;
  onFocusChange?: (focus: boolean) => void;
  disableErrorText?: boolean;
  inputRef?: any;
  inputRegex?: RegExp;
  inputMode?: any;
  onClick?: () => void;
  onResultClick?: (value: T) => void;
  onKeyDown?: (event: any) => void;
  optional?: boolean;
  optionalText?: string;
  result?: T[];
  loading?: boolean;
  onClickOutside?: () => void;
  renderDisplayValue: (result: T) => JSX.Element;
  isSearchInputSelectable?: {
    renderSelectableDisplayValue: (value: string) => JSX.Element;
  };
  searchMinLength?: number;
  errorMessage?: string;
  intersectionElementRef?: any;
}

const initialState = {
  focus: false,
  value: '',
};

const DesktopAutoComplete = <T,>(props: DesktopAutoCompleteProps<T>) => {
  const {
    disabled,
    disableErrorText,
    disabledJustInput,
    placeholder,
    value,
    icon,
    className,
    onChange,
    onBlurChange,
    onFocusChange,
    onClick,
    onKeyDown,
    onResultClick,
    optional,
    optionalText,
    inputMode,
    inputRef,
    inputRegex,
    result,
    loading,
    onClickOutside,
    renderDisplayValue,
    isSearchInputSelectable,
    errorMessage,
    searchMinLength = 2,
    intersectionElementRef,
  } = props;
  const [state, setState] = useState(initialState);
  const componentRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (componentRef.current && !componentRef.current.contains(event.target as Node)) {
      if (onClickOutside && result && result.length > 0) onClickOutside();
    }
  };

  const handleChange = (e: any) => {
    const valueV = e.currentTarget.value || '';
    const regexValue = inputRegex ? valueV.replace(inputRegex, '') : valueV;
    setState({
      ...state,
      value: regexValue || null,
    });
    if (onChange) {
      onChange(regexValue || null);
    }
  };

  useEffect(() => {
    if (typeof value !== 'undefined') {
      setState((oldState) => ({
        ...oldState,
        value,
      }));
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [componentRef, onClickOutside]);

  const isSelectableInputShown =
    isSearchInputSelectable && value && value.length >= searchMinLength && !loading;

  const hasAnyResult = result && result.length > 0;

  return (
    <div
      id='desktop-auto-complete'
      className={`text-input desktop-auto-complete ${errorMessage ? 'error ' : ''}${
        state.focus ? 'focus ' : ''
      }${disabled ? 'disabled ' : ''}${className ?? ''}`}
      data-testid='desktop-auto-complete'
    >
      <div className={`inner${hasAnyResult || loading ? ' full' : ' empty'}`}>
        <input
          className={state.value ? 'has-value' : ''}
          type='text'
          inputMode={inputMode ?? 'text'}
          value={state.value ?? ''}
          data-testid='auto-complete-input'
          onChange={handleChange}
          autoComplete='new-password' // Disable autocomplete for firefox
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          onKeyDown={(v) => {
            if (onKeyDown) onKeyDown(v);
          }}
          onFocus={() => {
            if (onFocusChange) {
              onFocusChange(true);
            }
            setState({ ...state, focus: true });
          }}
          onBlur={() => {
            setState({ ...state, focus: false });
            if (onFocusChange) {
              onFocusChange(false);
            }
            if (onBlurChange) {
              onBlurChange(state.value);
            }
          }}
          disabled={disabled}
          readOnly={disabledJustInput}
          ref={inputRef}
        />
        <div className='floating-label'>{`${placeholder}${
          optional && !state.value && !state.focus ? ` ${optionalText ?? '(optional)'}` : ''
        }`}</div>
        {icon && (
          <div className='append-inner'>
            <img className='help' src={icon} alt='' />
          </div>
        )}
      </div>
      {hasAnyResult || loading || isSelectableInputShown ? (
        <div
          className={`result-box${hasAnyResult || isSelectableInputShown ? ' full' : ' empty'}`}
          ref={componentRef}
        >
          {isSelectableInputShown && (
            <div
              className='possible-item'
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onClick={onClick}
            >
              {isSearchInputSelectable.renderSelectableDisplayValue(value)}
            </div>
          )}
          {result &&
            !loading &&
            result.map((possibleItem: T, index) => (
              <div
                onClick={() => {
                  if (onResultClick) {
                    onResultClick(possibleItem);
                  }
                }}
                key={`possible-item-${index}`}
                ref={result.length - 2 === index ? intersectionElementRef : null}
              >
                {renderDisplayValue(possibleItem)}
              </div>
            ))}
          {loading && <Spinner />}
        </div>
      ) : null}
      {errorMessage && !disableErrorText && <div className='error-text'> {errorMessage} </div>}
    </div>
  );
};

export default DesktopAutoComplete;
