import { parseUserInputDate } from './helper';
import {
  EMAIL_REGEXP,
  EMAIL_OR_NULL_REGEXP,
  GERMAN_IBAN_REGEXP,
  GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP,
  EMPTY_REGEXP,
  MORE_THAN_2_CHAR_REGEXP,
  MORE_THAN_2_CHAR_OR_NULL_REGEXP,
  PHONE_REGEXP,
  PHONE_OR_NULL_REGEXP,
  POST_CODE_REGEXP,
} from './regex';

export interface Validator {
  validate(input: string): ValidationResult;
}

export interface ValidationResult {
  valid: boolean;
  message?: string;
}

export type ValidationErrorNotification = Record<string, Record<string, ValidationResult>>;

class Validators {
  static dateValidator(): Validator {
    return {
      validate(input: string): ValidationResult {
        const parsedDate = parseUserInputDate(input);
        const valid = parsedDate.isValid();
        return {
          valid,
          message: valid ? '' : 'Bitte geben Sie ein gültiges Datum ein',
        };
      },
    };
  }

  static email(): Validator {
    return Validators.regexp(EMAIL_REGEXP, 'Bitte geben Sie eine gültige E-Mail Adresse ein');
  }

  static emailOrNull(): Validator {
    return Validators.regexp(
      EMAIL_OR_NULL_REGEXP,
      'Bitte geben Sie eine gültige E-Mail Adresse ein',
    );
  }

  static germanIban(): Validator {
    return Validators.regexp(GERMAN_IBAN_REGEXP, 'Bitte geben Sie eine gültige IBAN ein');
  }

  static germanIbanOrAnyForeign(): Validator {
    return Validators.regexp(
      GERMAN_IBAN_OR_ANY_FOREIGN_REGEXP,
      'Bitte geben Sie eine gültige IBAN ein',
    );
  }

  static empty(): Validator {
    return Validators.regexp(EMPTY_REGEXP, 'Bitte geben Sie die fehlenden Informationen ein');
  }

  static moreThan2(): Validator {
    return Validators.regexp(
      MORE_THAN_2_CHAR_REGEXP,
      'Ihre Eingabe muss mindestens zwei Buchstaben enthalten.',
    );
  }

  static moreThan2orNull(): Validator {
    return Validators.regexp(
      MORE_THAN_2_CHAR_OR_NULL_REGEXP,
      'Ihre Eingabe muss mindestens zwei Buchstaben enthalten.',
    );
  }

  static phone(): Validator {
    return Validators.regexp(PHONE_REGEXP, 'Bitte geben Sie eine gültige Telefonnummer ein');
  }

  static phoneOrNull(): Validator {
    return Validators.regexp(
      PHONE_OR_NULL_REGEXP,
      'Bitte geben Sie eine gültige Telefonnummer ein',
    );
  }

  static zipCode(): Validator {
    return Validators.regexp(POST_CODE_REGEXP, 'Bitte geben Sie eine Postleitzahl ein');
  }

  static regexp(expression: RegExp, message: string): Validator {
    return {
      validate(input: string): ValidationResult {
        const m = expression.exec(input);
        const valid = m !== null;
        return {
          valid,
          message: valid ? '' : message,
        };
      },
    };
  }
}

export default Validators;
