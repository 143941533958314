import React, { useEffect, useState } from 'react';
import { useKwsState } from '../../../../contexts/KwsStateContext/KwsStateContext';
import ServiceGateway from '../../../../services/ServiceGateway';
import Intro from '../../CommonSections/Intro';
import { isDesktopOrTablet, scrollToPosition } from '../../../../utils/helper';
import { StandingOrderDetailsModel } from '../../../../types/Overview/StandingOrderModel';
import SpinnerFullPage from '../../../Common/SpinnerFullPage';
import StandingOrderDetailTab from './components/StandingOrderDetailTab';
import AllTransactionsTab from '../../CommonSections/AllTransactionsTab';
import TabButtons from '../../../Common/TabButtons';
import ResetButton, { RESET_BUTTON_INTERSECTION } from '../../../Common/ResetButton';
import Container from '../../../Common/Container';
import './index.scss';
import { IntersectionObserverComponent } from '../../../../hooks/useIntersectionObserver';

const StandingOrderDetails = () => {
  const { kwsState, standingOrderToProcess, setStandingOrderToProcess, setSwitchingDateToProcess } =
    useKwsState();
  const { pageType, id: soId } = standingOrderToProcess!;
  const [standingOrderDetailState, setStandingOrderDetailState] =
    useState<StandingOrderDetailsModel>();
  const [hasShadow, setHasShadow] = useState(false);

  const getStandingOrder = async () => {
    switch (pageType) {
      case 'EDIT': {
        const response = await ServiceGateway.getStandingOrder(kwsState!.id, soId!);
        setStandingOrderDetailState(response.data);
        break;
      }
      case 'ADD': {
        const response = await ServiceGateway.preCreateStandingOrder(kwsState!.id);
        setStandingOrderDetailState(response.data);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    scrollToPosition('0');
    getStandingOrder();
  }, []);

  const goBack = () => {
    setStandingOrderToProcess(undefined);
    setSwitchingDateToProcess(false);
  };

  if (!standingOrderDetailState) {
    return <SpinnerFullPage />;
  }

  const tabData = [
    {
      id: 0,
      title: 'Dauerauftrag',
      content: (
        <StandingOrderDetailTab
          standingOrderDetailState={standingOrderDetailState}
          setStandingOrderDetailState={setStandingOrderDetailState}
        />
      ),
    },
    ...(standingOrderDetailState.transactions && standingOrderDetailState.transactions?.length !== 0
      ? [
          {
            id: 1,
            title: 'Transaktionen',
            content: <AllTransactionsTab transactions={standingOrderDetailState!.transactions!} />,
          },
        ]
      : []),
  ];

  const isTabsVisible = tabData.length > 1;

  return (
    <div className='standing-orders-details' data-testid='standing-orders-details'>
      <ResetButton
        title='zurück zur Übersicht'
        onClick={goBack}
        isSticky={!isDesktopOrTablet()}
        hasShadow={hasShadow}
      />

      {/* Item Info */}
      <div className={`intro-standing-order ${isTabsVisible ? 'bottom-margin-zero' : ''}`}>
        <Container>
          {pageType === 'EDIT' ? (
            <Intro data={standingOrderDetailState} pageType='standingOrder' />
          ) : (
            <h2>Dauerauftrag einrichten</h2>
          )}
        </Container>
      </div>
      {isTabsVisible && isDesktopOrTablet() ? null : (
        <IntersectionObserverComponent
          onIntersection={(isIntersecting) => setHasShadow(!isIntersecting)}
          placeOfIntersection={RESET_BUTTON_INTERSECTION}
        />
      )}

      {isTabsVisible ? (
        <div className={isDesktopOrTablet() ? 'stick-to-top' : 'stick-to-top-50'}>
          <TabButtons tabs={tabData} />
        </div>
      ) : (
        tabData[0].content
      )}
    </div>
  );
};

export default StandingOrderDetails;
