import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import {
  CustomerChoice,
  FetchDacResponse,
  InitFinApiResponse,
  ServiceGatewayInterface,
  ServiceStart,
  ServiceTestResponse,
} from './ServiceGateway.types';
import { Overview } from '../types/Overview/Overview';
import { isProd } from '../utils/environment';
import {
  EditNotificationsModel,
  NotificationDetailsModel,
  EditNotificationModel,
} from '../types/Overview/NotificationModel';
import {
  EditStandingOrdersModel,
  StandingOrderDetailsModel,
} from '../types/Overview/StandingOrderModel';
import { BankSearchModel, DacPageModel } from '../types/DacPage/DacPageModel';
import { ThanksPageModel } from '../types/ThanksPage/ThanksPageModel';
import { SignaturePageModel } from '../types/SignaturePage/SignaturePageModel';
import { Frequency, RecipientSearchModel } from '../types/Overview/Common';
import { DacProvider } from '../types/DacProvider';
import { DeviceOutputEnum } from '../utils/deviceOutput';

declare const Check24: any;

export const client = axios.create({
  baseURL: '/frontend',
});

const ServiceGateway: ServiceGatewayInterface = {
  startTestCase: (testParams): Promise<AxiosResponse<ServiceTestResponse>> => {
    const {
      ibanSource,
      ibanTarget,
      email,
      invalidSwitchingDates,
      birthdate,
      testcase,
      fintecDac,
      fintecStandingOrders,
      bankId,
      ssoUserId,
      mobileNumber,
      overviewData,
      partner,
    } = testParams;
    return client.post('/testcase', {
      email,
      invalidSwitchingDates,
      birthday: birthdate,
      state: testcase,
      bankIdTarget: bankId,
      ibanSource: ibanSource || undefined,
      ibanTarget: ibanTarget || undefined,
      fintecDac: fintecDac || undefined,
      fintecStandingOrders: fintecStandingOrders || undefined,
      ssoUserId: ssoUserId || undefined,
      mobileNumber: mobileNumber || undefined,
      overviewData: overviewData || undefined,
      partner: partner || undefined,
    });
  },

  startProcess: (id: string): Promise<AxiosResponse<ServiceStart>> => client.post(`/${id}/start`),

  getProcessState: (id: string): Promise<AxiosResponse<any>> =>
    client.get('/page/state', {
      params: {
        process_id: id,
      },
    }),

  approveProcess: (id: string): Promise<AxiosResponse<any>> => client.put(`/${id}/approveProcess`),

  completeEditing: (id: string): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/complete-editing`),

  backOnEditing: (id: string): Promise<AxiosResponse<any>> => client.put(`/${id}/back-on-editing`),

  resetProcess: (id: string): Promise<AxiosResponse<void>> => client.put(`/${id}/reset`),

  approveNotifications: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/approveNotifications`, data),

  editNotifications: (
    id: string,
    data: CustomerChoice,
  ): Promise<AxiosResponse<EditNotificationsModel>> =>
    client.put(`/${id}/edit-state-notifications`, data),

  editSwitchingDate: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/globalSwitchingDates`, data),

  invalidSwitchingDates: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/invalidSwitchingDates`, data),

  editClosingAccount: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/editClosingData`, data),

  editStandingOrders: (
    id: string,
    data: CustomerChoice,
  ): Promise<AxiosResponse<EditStandingOrdersModel>> =>
    client.put(`/${id}/edit-state-standing-orders`, data),

  closingData: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/closingData`, data),

  editClosingData: (id: string, selected: boolean): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/editClosingData?selected=${selected}`),

  attach: (id: string, birthdate: string): Promise<AxiosResponse<void>> =>
    client.put(`/${id}/attach?birthday=${birthdate}`),

  attachTargetIban: (id: string, iban: string): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/attachTargetIban?targetIban=${iban}`),

  initTink: (id: string): Promise<AxiosResponse<string>> => client.post(`/${id}/dac/init`),

  initFinApi: (
    id: string,
    deviceoutput?: DeviceOutputEnum,
  ): Promise<AxiosResponse<InitFinApiResponse>> =>
    client.get(`/fin-api/init/${id}?device_output=${deviceoutput}`),

  resetTink: (id: string): Promise<AxiosResponse<string>> => client.post(`/${id}/dac/reset`),

  getProvider: (id: string): Promise<AxiosResponse<DacProvider>> =>
    client.get(`/dac/provider?process_id=${id}`),

  fetchTinkDac: (id: string): Promise<AxiosResponse<FetchDacResponse>> =>
    client.put(`/${id}/dac/fetch`),

  fetchFinApiDac: (id: string): Promise<AxiosResponse<FetchDacResponse>> =>
    client.get(`/fin-api/fetch/${id}`),

  tinkError: (id: string, wizardKey: string, error: any): Promise<AxiosResponse<string>> =>
    client.post(`/${id}/dac/error?wizardKey=${wizardKey}`, error),

  finApiError: (id: string): Promise<AxiosResponse<string>> => client.post(`/fin-api/errors/${id}`),

  attachIbanSource: (id: string, ibanSource: string): Promise<AxiosResponse<void>> =>
    client.post(`/${id}/attachIbanSource?ibanSource=${ibanSource}`),

  getOverviewPage: (id: string): Promise<AxiosResponse<Overview>> =>
    client.get('/page/overview', {
      params: {
        process_id: id,
      },
    }),

  getDacPage: (id: string): Promise<AxiosResponse<DacPageModel>> =>
    client.get('/page/dac-needed', {
      params: {
        process_id: id,
      },
    }),

  getThanksPage: (id: string): Promise<AxiosResponse<ThanksPageModel>> =>
    client.get('/page/thanks', {
      params: {
        process_id: id,
      },
    }),

  getSignaturePage: (id: string): Promise<AxiosResponse<SignaturePageModel>> =>
    client.get('/page/signature', {
      params: {
        process_id: id,
      },
    }),

  getPossibleFrequencies: (id: string): Promise<AxiosResponse<any>> =>
    client.get(`/${id}/getPossibleFrequencies`),

  sign: (
    id: string,
    deviceType: string,
    deviceName: string,
    imageFile: File,
  ): Promise<AxiosResponse<any>> => {
    const url = `/${id}/sign?deviceType=${deviceType}&deviceName=${deviceName}`;
    const bodyFormData = new FormData();
    bodyFormData.append('file', imageFile);
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return client.post(url, bodyFormData, config);
  },

  updateNotification: (
    id: string,
    data: EditNotificationModel,
  ): Promise<AxiosResponse<EditNotificationModel>> => client.put(`/${id}/updateNotification`, data),

  updateStandingOrder: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/updateStandingOrder`, data),

  createNotification: (
    id: string,
    data: EditNotificationModel,
  ): Promise<AxiosResponse<EditNotificationModel>> =>
    client.post(`/${id}/createNotification`, data),

  preCreateNotification: (id: string): Promise<AxiosResponse<NotificationDetailsModel>> =>
    client.post(`/${id}/preCreateNotification`),

  preCreateStandingOrder: (id: string): Promise<AxiosResponse<any>> =>
    client.post(`/${id}/preCreateStandingOrder`),

  getNotification: (
    id: string,
    notificationId: string,
  ): Promise<AxiosResponse<NotificationDetailsModel>> =>
    client.get(`/${id}/notification/${notificationId}`),

  getStandingOrder: (
    id: string,
    standingOrderId: string,
  ): Promise<AxiosResponse<StandingOrderDetailsModel>> =>
    client.get(`/${id}/standingOrder/${standingOrderId}`),

  createStandingOrder: (id: string, data: any): Promise<AxiosResponse<any>> =>
    client.post(`/${id}/createStandingOrder`, data),

  getAddresses: (address: string): Promise<AxiosResponse<any>> =>
    client.get(`/address/search/${address}`),

  downloadPdfSummary: (id: string): Promise<AxiosResponse<any>> =>
    client.get(`/${id}/downloadPdfSummary`, {
      responseType: 'blob',
    }),

  logout: (id: string): Promise<AxiosResponse<string>> => client.get(`/${id}/logout`),

  downloadPowerOfAttorney: (id: string): Promise<AxiosResponse<any>> => {
    const c24BankCookie = Cookies.get('c24bank-session');
    return client.get(`/${id}/powerOfAttorney${c24BankCookie ? `?token=${c24BankCookie}` : ''}`);
  },

  getPossibleBankList: (
    searchTerm: string,
    page = 1,
    size = 10,
  ): Promise<AxiosResponse<BankSearchModel>> =>
    client.get('/bank/search', { params: { search_term: searchTerm, page, size } }),

  getPossibleRecipientList: (searchTerm: string): Promise<AxiosResponse<RecipientSearchModel[]>> =>
    client.get('/recipient/search', {
      params: {
        name: searchTerm,
      },
    }),

  attachBankCodeSource: (id, bankCode): Promise<AxiosResponse<any>> =>
    client.put(`/${id}/attachBankCodeSource?bankCode=${bankCode}`),

  sendSmsCode: (id: string): Promise<AxiosResponse<any>> => client.post(`/${id}/sendSmsCode`),

  verifySms: (id: string, token: string, code: string): Promise<AxiosResponse<any>> =>
    client.post(`/${id}/verify`, { token, code }),

  firstExecutionDate: (
    id: string,
    standingOrderId: string,
    switchingDate: string,
    frequency: Frequency,
  ): Promise<AxiosResponse<any>> =>
    client.get(
      `/${id}/first-execution-date-with-frequency/${standingOrderId}?switching_date=${switchingDate}&frequency=${frequency}`,
    ),
};

const clearCookies = () => {
  Cookies.remove('c24session', { path: '/' });
  Cookies.remove('c24bank-session', {
    path: '/',
  });

  if (typeof Check24 !== 'undefined') {
    Check24.cookieBanner?.c24consent?.unsetCookie('c24session');
  }
};

export const logoutSystem = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  ServiceGateway.logout(id!).then(() => {
    clearCookies();
    window.location.reload();
  });
};

client.interceptors.request.use((request) => {
  const urlParams = new URLSearchParams(window.location.search);
  const channel = urlParams.get('channel');
  const device = window.wf_deviceoutput;

  if (request.headers) {
    if (device) request.headers.device = device;
    if (channel) request.headers.channel = channel;
    request.headers['Cache-Control'] = 'no-cache';
  }
  return request;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response || {};
    if (status === 410) {
      if (window.top) {
        if (isProd()) {
          window.top.postMessage({ urlChange: data }, 'https://banking.c24.de/');
        } else {
          window.top.postMessage({ urlChange: data }, '*');
        }
      }
      clearCookies();
      window.location.href = data;
    } else if (status === 401 || status === 406) {
      clearCookies();
      window.location.reload();
    } else if (status > 400 || error.message === 'Network Error') {
      // 400 status used for form validations
      window.location.href = `${window.location.origin}/start/error`;
    }
    return Promise.reject(error);
  },
);

export default ServiceGateway;
